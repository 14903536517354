<template>
  <div>
    <headerNav activeIndex="2" productsIndex="5"></headerNav>
    <div class="productsBody">
      <div class="productsImg">
        <div>
          <router-link to="/contact"><el-button type="primary" class="formBtn" >免费咨询</el-button></router-link>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle1.png" />
      </div>
      <div class="productsCard">
        <div class="cardDiv">
          <img src="../../assets/img/cardImg5.png" style="margin-top: 58px;"/>
          <div class="cardTitle" style="margin-top: 48px;">快速响应</div>
          <div>快速解决客户需求</div>
          <div>为您提供完善运输方案</div>
        </div>
        <div class="cardDiv">
          <img src="../../assets/img/cardImg6.png" style="margin-top: 41px;"/>
          <div class="cardTitle" style="margin-top: 49px;">安全无忧</div>
          <div>平台担保,严格审核认证</div>
          <div>每家承运商与车辆</div>
        </div>
        <div class="cardDiv">
          <img src="../../assets/img/cardImg7.png"  style="margin-top: 47px;"/>
          <div class="cardTitle" style="margin-top: 55px;">优质服务</div>
          <div>已赢得万千货主选择</div>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle2.png" />
      </div>
      <div class="structure">
        <img src="../../assets/img/structureImg6.png" />
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle3.png" />
      </div>
      <div class="case">
        <img src="../../assets/img/caseImg4.png" />
      </div>
    </div>
    <contactNav></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{},
      rules: {

      }
    };
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.productsBody{
  width:100%;
  min-height: 800px;
  .productsImg{
    width:100%;
    height:624px;
    background:url("../../assets/img/productsBg4.png") no-repeat center;
    div{
      width: 1200px;
      margin: 0 auto;
      .formBtn{
        width: 176px;
        height: 50px;
        background: #4C6DFD;
        border-radius: 8px;
        position: relative;
        top: 450px;
        border: none;
      }
      .formBtn:hover{
        background: #7D93F5;
        border-radius: 8px;
      }
    }
  }
  .titleImg{
    width:1200px;
    margin:40px auto;
    margin-top: 60px;
    text-align: center;
  }
  .productsCard{
    width:1200px;
    height:347px;
    margin:0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cardDiv{
      width:377px;
      height:349px;
      background:url("../../assets/img/cardImg.png") no-repeat center;
      flex-direction: column;
      display: flex;
      align-items: center;
      cursor:pointer;
      transition: all  .2s  ease-in;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #393939;
      line-height: 24px;
      text-align: center;
      font-style: normal;
      .cardTitle{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 28px;
        color: #050829;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        margin-bottom: 20px;
      }
    }
    .cardDiv:hover{
      transform: scale(1.05);
    }
  }
  .structure{
    width:100%;
    height:954px;
    background: #F0F9FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .case{
    width:1200px;
    margin:0px auto;
    margin-bottom: 100px;
  }

}
</style>
