var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerNav',{attrs:{"activeIndex":"2","productsIndex":"5"}}),_c('div',{staticClass:"productsBody"},[_c('div',{staticClass:"productsImg"},[_c('div',[_c('router-link',{attrs:{"to":"/contact"}},[_c('el-button',{staticClass:"formBtn",attrs:{"type":"primary"}},[_vm._v("免费咨询")])],1)],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('contactNav'),_c('footerNav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("../../assets/img/ldyTitle1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productsCard"},[_c('div',{staticClass:"cardDiv"},[_c('img',{staticStyle:{"margin-top":"58px"},attrs:{"src":require("../../assets/img/cardImg5.png")}}),_c('div',{staticClass:"cardTitle",staticStyle:{"margin-top":"48px"}},[_vm._v("快速响应")]),_c('div',[_vm._v("快速解决客户需求")]),_c('div',[_vm._v("为您提供完善运输方案")])]),_c('div',{staticClass:"cardDiv"},[_c('img',{staticStyle:{"margin-top":"41px"},attrs:{"src":require("../../assets/img/cardImg6.png")}}),_c('div',{staticClass:"cardTitle",staticStyle:{"margin-top":"49px"}},[_vm._v("安全无忧")]),_c('div',[_vm._v("平台担保,严格审核认证")]),_c('div',[_vm._v("每家承运商与车辆")])]),_c('div',{staticClass:"cardDiv"},[_c('img',{staticStyle:{"margin-top":"47px"},attrs:{"src":require("../../assets/img/cardImg7.png")}}),_c('div',{staticClass:"cardTitle",staticStyle:{"margin-top":"55px"}},[_vm._v("优质服务")]),_c('div',[_vm._v("已赢得万千货主选择")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("../../assets/img/ldyTitle2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure"},[_c('img',{attrs:{"src":require("../../assets/img/structureImg6.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("../../assets/img/ldyTitle3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_c('img',{attrs:{"src":require("../../assets/img/caseImg4.png")}})])
}]

export { render, staticRenderFns }